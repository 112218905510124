import { lazy } from 'react';

import { loadTranslations } from './translationLoader';

interface LazyLoadProps {
  component?: string;
  componentPath: string;
  folder: string;
  additionalTranslationFolders?: Array<string>;
}

export function lazyLoad({
  folder,
  componentPath,
  component,
  additionalTranslationFolders,
}: LazyLoadProps) {
  const language = 'es';
  const translationFolders = [folder].concat(additionalTranslationFolders || []);

  return lazy(async () => {
    loadTranslations(translationFolders, language);
    const path = `${folder}/${componentPath}`;
    const module = await import(`../features/${path}.tsx`);
    return {
      default: component ? module[component] : module.default,
    };
  });
}
