// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import React from 'react';

import bolt11 from 'bolt11';

import { makeSidebarToggleable } from '../dom';
import { lazyLoad } from '../utils/lazyLoad';
import register from '../utils/register';

import './i18n';

const AlertsBar = React.lazy(() => import('../features/alerts_bar/alerts_bar'));
const BasicVerification = React.lazy(() =>
  import('../features/basic_verification/pages/BasicVerification').then((module) => ({
    default: module.BasicVerification,
  })),
);

const Verification = lazyLoad({
  folder: 'verification',
  componentPath: 'pages/Verification',
  component: 'Verification',
});

const BillingInvoices = React.lazy(
  () => import('../features/billing_invoices/pages/BillingInvoicesList'),
);
const DepositSuccess = React.lazy(() =>
  import('../features/deposits/pages/DepositSuccess').then((module) => ({
    default: module.DepositSuccess,
  })),
);
const KushkiDepositResult = React.lazy(() =>
  import('../features/deposits/pages/KushkiDepositResult').then((module) => ({
    default: module.KushkiDepositResult,
  })),
);
const Fees = React.lazy(() => import('../features/fees/pages/fees'));
const History = lazyLoad({
  folder: 'history',
  componentPath: 'pages/History',
  component: 'HistoryWrapper',
});
const Deposits = React.lazy(() => import('../features/deposits/pages/Deposits'));
const InvestmentsBalances = lazyLoad({
  folder: 'investments',
  componentPath: 'pages/InvestmentsBalances',
  component: 'InvestmentsBalances',
  additionalTranslationFolders: ['movements'],
});
const InvestorProfile = React.lazy(() =>
  import('../features/investor_profile/pages/InvestorProfile').then((module) => ({
    default: module.InvestorProfile,
  })),
);
const Loans = React.lazy(() => import('../features/loans/pages/Loans'));

const LoansLandingView = React.lazy(() =>
  import('../features/loans_landing/pages/LoansLandingView').then((module) => ({
    default: module.LoansLandingView,
  })),
);
const LoansSummary = React.lazy(() => import('../features/loans_summary/pages/LoansSummary'));
const Portfolio = lazyLoad({
  folder: 'portfolio',
  componentPath: 'pages/Portfolio',
  component: 'Portfolio',
  additionalTranslationFolders: ['investments', 'header', 'graphs'],
});
const MobileConfirmation = React.lazy(
  () => import('../features/mobile_confirmation/mobile_confirmation'),
);
const PortfolioNewUsersInstructions = React.lazy(
  () => import('../features/portfolio/components/NewUsersInstructions'),
);
const NewBankAccount = React.lazy(() =>
  import('../features/portfolio/components/NewBankAccount').then((module) => ({
    default: module.NewBankAccount,
  })),
);
const ReferralsPayouts = React.lazy(
  () => import('../features/referrals-payouts/pages/ReferralsPayouts'),
);
const Referrals = React.lazy(() => import('../features/referrals/pages/Referrals'));
const Reimbursements = React.lazy(() => import('../features/reimbursements/pages/reimbursements'));
const Sidebar = lazyLoad({
  folder: 'sidebar',
  componentPath: 'Sidebar',
  component: 'SidebarWrapper',
});

const ScheduledOrdersWrapper = React.lazy(() =>
  import('../features/scheduled_orders/pages/ScheduledOrders').then((module) => ({
    default: module.ScheduledOrdersWrapper,
  })),
);
const ScheduledOrdersConfigure = React.lazy(() =>
  import('../features/scheduled_orders/pages/ScheduledOrdersConfigure').then((module) => ({
    default: module.ScheduledOrdersConfigure,
  })),
);
const ConfigureUserPublicSiteWrapper = lazyLoad({
  folder: 'user_public_site',
  componentPath: 'pages/Configure',
  component: 'ConfigureUserPublicSiteWrapper',
  additionalTranslationFolders: ['header'],
});
const UserPublicSiteWrapper = lazyLoad({
  folder: 'user_public_site',
  componentPath: 'pages/UserPublicSite',
  component: 'UserPublicSiteWrapper',
});
const CancelInstructions = React.lazy(
  () => import('../features/withdrawal_verification_form/components/cancelInstructions'),
);
const ScamWarning = React.lazy(() =>
  import('../features/withdrawals/components/ScamWarning').then((module) => ({
    default: module.ScamWarning,
  })),
);
const SidebarTutorial = React.lazy(() => import('../shared/components/SidebarTutorial'));
const PriceAlerts = lazyLoad({
  folder: 'price_alerts',
  componentPath: 'pages/PriceAlerts',
  component: 'PriceAlertsWrapper',
});
const PriceAlertsConfigure = lazyLoad({
  folder: 'price_alerts',
  componentPath: 'pages/PriceAlertsConfigure',
  component: 'PriceAlertsConfigure',
});

const Alert = React.lazy(() =>
  import('../shared/components/Alert').then((module) => ({
    default: module.Alert,
  })),
);

const NewBank = React.lazy(() => import('../features/new_bank/pages/NewBank'));

const Movements = lazyLoad({
  folder: 'movements',
  componentPath: 'pages/Movements',
  component: 'Movements',
  additionalTranslationFolders: ['graphs'],
});

const ActionsSidebar = React.lazy(() =>
  import('../shared/components/ActionsSideBar').then((module) => ({
    default: module.ActionsSidebar,
  })),
);

const TradingView = lazyLoad({
  folder: 'tradingview',
  componentPath: 'pages/TradingView',
  component: 'TradingView',
});

const GoalMovements = React.lazy(() =>
  import('../features/operations/pages/GoalMovements').then((module) => ({
    default: module.GoalMovementsWrapper,
  })),
);

const Transfers = lazyLoad({
  folder: 'operations',
  componentPath: 'pages/Transfers',
  component: 'TransferWrapper',
});

const Transactions = lazyLoad({
  folder: 'operations',
  componentPath: 'pages/Transactions',
  component: 'TransactionWrapper',
});

const RFQOrders = React.lazy(() =>
  import('../features/otc/pages/RFQOrders').then((module) => ({
    default: module.RFQOrdersWrapper,
  })),
);

const Remittances = lazyLoad({
  folder: 'remittances',
  componentPath: 'pages/Remittances',
  component: 'RemittancesWrapper',
});

const HeaderWrapper = lazyLoad({
  folder: 'header',
  componentPath: 'Header',
  component: 'HeaderWrapper',
});

const DepositsNavigation = React.lazy(() =>
  import('../features/deposits/components/Navigation').then((module) => ({
    default: module.DepositsNavigation,
  })),
);

const UserPanel = React.lazy(() =>
  import('../features/user_panel/pages/UserPanel').then((module) => ({
    default: module.UserPanel,
  })),
);

const WithdrawalNavigation = React.lazy(() =>
  import('../features/withdrawals/components/Navigation').then((module) => ({
    default: module.WithdrawalNavigation,
  })),
);

const ApiKeys = lazyLoad({
  folder: 'api_keys',
  componentPath: 'pages/ApiKeys',
  component: 'ApiKeysWrapper',
});
const Withdrawal = lazyLoad({
  folder: 'safe_process_attempts',
  componentPath: 'pages/Withdrawal',
  component: 'WithdrawalWrapper',
});

const ToastContainer = React.lazy(() =>
  import('../shared/components/Toast').then((module) => ({
    default: module.ToastContainer,
  })),
);

const DepositError = React.lazy(() =>
  import('../features/deposits/pages/DepositError').then((module) => ({
    default: module.DepositError,
  })),
);

const KushkiResults = lazyLoad({
  folder: 'deposits',
  componentPath: 'pages/CardDeposit',
  component: 'CardDepositWrapper',
});

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */

if (module.hot) {
  module.hot.accept();
}

require('./application.css');

register('alerts-bar', AlertsBar);
register('basic-verification', BasicVerification);
register('verification', Verification);
register('billing-invoices', BillingInvoices);
register('fees', Fees);
register('mobile-confirmation', MobileConfirmation);
register('reimbursements', Reimbursements);
register('loans-landing-view', LoansLandingView);
register('portfolio-new-users-instructions', PortfolioNewUsersInstructions);
register('loans', Loans);
register('investments-balances', InvestmentsBalances);
register('investor-profile', InvestorProfile);
register('history', History);
register('scheduled-orders', ScheduledOrdersWrapper);
register('scheduled-orders-configure', ScheduledOrdersConfigure);
register('user-public-site', UserPublicSiteWrapper);
register('configure-user-public-site', ConfigureUserPublicSiteWrapper);
register('loans-summary', LoansSummary);
register('portfolio', Portfolio);
register('referrals-payouts', ReferralsPayouts);
register('referrals', Referrals);
register('deposit-success', DepositSuccess);
register('kushki-deposit-result', KushkiDepositResult);
register('sidebar-tutorial', SidebarTutorial);
register('deposits', Deposits);
register('sidebar', Sidebar);
register('withdrawal-cancel-instructions', CancelInstructions);
register('alert', Alert);
register('withdrawal-scam-warning', ScamWarning);
register('price-alerts', PriceAlerts);
register('price-alerts-configure', PriceAlertsConfigure);
register('new-bank-modal', NewBankAccount);
register('new-bank', NewBank);
register('currency-movements', Movements);
register('tradingview', TradingView);
register('goal-movements', GoalMovements);
register('transfers', Transfers);
register('transactions', Transactions);
register('rfq-orders', RFQOrders);
register('remittances', Remittances);
register('new-header', HeaderWrapper);
register('deposits-navigation', DepositsNavigation);
register('user-panel', UserPanel);
register('withdrawal-navigation', WithdrawalNavigation);
register('actions-sidebar', ActionsSidebar);
register('api-keys', ApiKeys);
register('toast-container', ToastContainer);
register('deposit-error', DepositError);
register('react-withdrawal', Withdrawal);
register('kushki-results', KushkiResults);

window.addEventListener('DOMContentLoaded', makeSidebarToggleable);

(global as unknown).lightningPayReq = bolt11;

/* eslint-enable @typescript-eslint/no-unsafe-call */
/* eslint-enable @typescript-eslint/no-unsafe-member-access */
/* eslint-enable @typescript-eslint/no-unsafe-return */
