import i18next from 'i18next';

async function loadTranslationsForFolder(folder: string, language: string) {
  const translationModule = await import(`../features/${folder}/locales/${language}/${folder}.yml`);

  return translationModule.default;
}

export function loadTranslations(folders: Array<string>, language: string) {
  folders.forEach((folder) => {
    loadTranslationsForFolder(folder, language)
      .then((translations) => {
        i18next.addResourceBundle(language, folder, translations);
      })
      .catch(() => {});
  });
}
